import React from "react"
import classNames from "classnames"
import styles from "./utils/elements.module.scss"

const MedicineBox = ({ children, handleDelete }) => {
  return (
    <div
      style={{
        border: "2px solid #e8e8e8",
        borderRadius: "8px",
        padding: "1rem",
        position: "relative",
      }}
      className="mt-1"
    >
      {handleDelete ? (
        <span
          role="button"
          className="delete"
          style={{
            right: "0.5rem",
            position: "absolute",
            top: "0.5rem",
            zIndex: "100",
          }}
          onClick={handleDelete}
        />
      ) : null}
      <div>{children}</div>
    </div>
  )
}

export default MedicineBox
